import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Link, Container, Grid, Button, Card, Box, Typography, useMediaQuery } from '@mui/material'; // Aggiungi qui i componenti mancanti
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import UnlockContent from './unlockcontent';
import '../App.css';
import CryptoJS from 'crypto-js';

import {
  Dialog,
  DialogContent,
  DialogTitle,
} from '@mui/material';


function timeAgo(dateString) {
  const date = new Date(dateString);
  const now = new Date();
  const seconds = Math.floor((now - date) / 1000);



  

  const intervals = {
    year: 31536000,
    month: 2592000,
    day: 86400,
    hour: 3600,
    minute: 60,
    second: 1,
  };

  for (const [unit, value] of Object.entries(intervals)) {
    const interval = Math.floor(seconds / value);
    if (interval >= 1) {
      return `${interval} ${unit}${interval > 1 ? 's' : ''} ago`;
    }
  }
  return 'just now';
}


function md5ToBase64(url) {
  // Step 1: Convert the URL to an MD5 hash
  const md5Hash = CryptoJS.MD5(url).toString();
  
  // Step 2: Create an array with the hash and the additional value
  const array = [md5Hash, "22dcbe26d606977f9484257ea466af9254979b97"];
  
  // Step 3: Convert the array to a JSON string
  const jsonString = JSON.stringify(array);
  
  // Step 4: Encode the JSON string to base64
  const base64String = btoa(jsonString);
  
  return base64String;
}

  

const CardStep = () => {
  const { id } = useParams();
  const [card, setCard] = useState(null);
  const [step, setStep] = useState(1);
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [openDrawer, setOpenDrawer] = useState(false);


const [openTutorial, setOpenTutorial] = useState(false); // Stato per il video tutorial

  const handleOpenTutorial = () => {
    setOpenTutorial(true);
  };

  const handleCloseTutorial = () => {
    setOpenTutorial(false);
  };


   const [data, setData] = useState([]);
   const [loading, setLoading] = useState(false);
  useEffect(() => {
    const fetchCard = async () => {
      try {
        const response = await fetch(`https://hiddenvideos.vip/api/card/${id}`, {
        method: 'GET',
        headers: {
          'Authorization': '=1l<*uq8Ks8Fhxg5-]b',
          'Content-Type': 'application/json'
        }
      });
        if (!response.ok) {
          throw new Error('Card not found');
          window.location.href = "/"
        }
        const data = await response.json();
        setCard(data);
      } catch (error) {
        window.location.href = "/"
      }
    };
    fetchCard();
  }, [id]);

  const title = card?.title || 'N/A';
  const time = card?.time || '';
  const category = card?.category || '';

  const imageUrl = card?.imageUrl || '';
  const link = card?.link || '';
  const type = card?.type || '';

  const timetogo = timeAgo(time);


      const handleClick = () => {
      let param = md5ToBase64(`https://hiddenvideos.vip/card/${id}`)
      navigator.clipboard.writeText(`https://hiddenvideos.vip/card/${id}?u=${param}`).then(() => {
        setOpen(true);
      });
    };
    const handleClose = () => {
      setOpen(false);
    };



   const handleClickOpen = async () => {
    setLoading(true);
    try {

      const response = await fetch(`https://hiddenvideos.vip/api/more?q=${title}`, {
        method: 'GET',
        headers: {
          'Authorization': '=1l<*uq8Ks8Fhxg5-]b',
          'Content-Type': 'application/json'
        }
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      const result = await response.json();
      setData(result);
      setOpenDrawer(true);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  const handleCloseDrawer = () => {
    setOpenDrawer(false);
  };

  const handleCardClick = (id) => {
    navigate(`/category/${id}`); // Naviga alla rotta /category/:id
  };
  const openlink = (id) => {
    let param = md5ToBase64(`https://hiddenvideos.vip/card/${id}`)
    navigate(`/card/${id}?u=${param}`); // Naviga alla rotta /category/:id
    setOpenDrawer(false);
  };
  const [categories, setCategories] = useState([]);
  const [categoryName, setCategoryName] = useState(category);
  useEffect(() => {
    const fetchCategories = async () => {
      try {


      const response = await fetch(`https://hiddenvideos.vip/api/categories`, {
        method: 'GET',
        headers: {
          'Authorization': '=1l<*uq8Ks8Fhxg5-]b',
          'Content-Type': 'application/json'
        }
      });

        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const result = await response.json();
        setCategories(result);
      } catch (error) {
      }
    };
    fetchCategories();
  }, []);

  const getCategoryName = (cats) =>{
    if (categories.length > 0) {
      const categoryObj = categories.find(cat => cat.id === cats);

      if (categoryObj) {
        return categoryObj.name
      }
      return "Uncategory"
    }
  }
  // Update category name based on category ID
  useEffect(() => {
    if (categories.length > 0) {
      const categoryObj = categories.find(cat => cat.id === category);
      if (categoryObj) {
        setCategoryName(categoryObj.name);
      }
    }
  }, [categories, category]);
const [gotoFunction, setGotoFunction] = useState(() => () => {});
const isMobile = useMediaQuery('(max-width:600px)');

  return (
    <Container container spacing={2} justifyContent="center">


    <Grid container spacing={2} justifyContent="center" sx={{ marginTop: 1, ml: -1}}>
    <Card sx={{ width: 300, height: 550, margin: 2, position: 'relative', overflow: 'hidden', display: 'flex', flexDirection: 'column', padding: 2, borderRadius: 3, border:'1px solid rgba(23, 23, 23, 0.2)' }}>

      {/* Immagine di sfondo blurata */}
      <Box
        sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundImage: `url(${imageUrl})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          filter: 'blur(10px)',
          zIndex: 1,
          opacity: 1, // Adjust opacity for the right effect
        }}
      />

      {/* Box con le info */}
      <Box
        sx={{
          position: 'relative',
          zIndex: 2,
          backgroundColor: '#000000',
          color: 'white',
          padding: 1,
          width: '100%',
          boxSizing: 'border-box',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          height: '150px', // Adjust the height as needed
          borderBottom: '1px solid rgba(255, 255, 255, 0.2)', // Optional: Add a border to separate from the image
          borderRadius: 5
        }}
      >
      <Container>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={12}>
      <Typography variant="h6" component="h2" sx={{ mb: 0.3 }}>
        {title}
      </Typography>
        </Grid>

        <Grid item xs={12} sm={12} md={12}>
          <Typography variant="body2" sx={{ mb: 0.3, color: 'white', display: 'flex', alignItems: 'center', cursor: 'pointer' }}   onClick={() => handleCardClick(category)} >
            <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" style={{ marginRight: '4px', verticalAlign: 'middle' }}>
              <path d="m7.5 4.27 9 5.15"></path>
              <path d="M21 8a2 2 0 0 0-1-1.73l-7-4a2 2 0 0 0-2 0l-7 4A2 2 0 0 0 3 8v8a2 2 0 0 0 1 1.73l7 4a2 2 0 0 0 2 0l7-4A2 2 0 0 0 21 16Z"></path>
              <path d="m3.3 7 8.7 5 8.7-5"></path>
              <path d="M12 22V12"></path>
            </svg>
                  {getCategoryName(category)}
          </Typography>
        </Grid>

        <Grid item xs={12} sm={12} md={12}>
          <Typography variant="body2" sx={{ color: 'white', display: 'flex', alignItems: 'center' }}>
            <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" style={{ marginRight: '4px', verticalAlign: 'middle' }}>
              <path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4"></path>
              <polyline points="17 8 12 3 7 8"></polyline>
              <line x1="12" x2="12" y1="3" y2="15"></line>
            </svg>
            {timetogo}
          </Typography>
        </Grid>
        </Grid>

        </Container>
      </Box>

      {/* Immagine centrale */}
      <Box
        sx={{
          position: 'relative',
          zIndex: 2,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexGrow: 1,
          padding: 2,
          marginTop: 'auto',
          height: 10
        }}
      >
        <img
          src={imageUrl}
          alt={title}
          style={{ width: '80%', objectFit: 'cover', borderRadius: '8px' }} // Adjust size and border radius as needed
        />
      </Box>

      <Box
  sx={{
    display: 'flex',
    flexDirection: 'column', // Organizza i pulsanti in colonne
    justifyContent: 'space-between', // Spaziatura tra i gruppi di pulsanti
    padding: 1,
    backgroundColor: 'rgba(28, 28, 28, 0.8)',
    zIndex: 999,
    marginTop: -1,
    borderRadius: 3,
    height: '100px', // Altezza per distribuire correttamente i pulsanti
  }}
>
<Box
    sx={{
      display: 'flex',
      justifyContent: 'center', // Spaziatura tra i due pulsanti
    }}
  >
            <UnlockContent id={id} finalLink={link}  type={type}  />
</Box>
<Box
    sx={{
      display: 'flex',
      justifyContent: 'space-between', // Spaziatura tra i due pulsanti
    }}
  >
<Button
          onClick={handleOpenTutorial}
          variant="contained"
          sx={{
            backgroundColor: '#000000',
            '&:hover': {
              backgroundColor: '#1e1e1e',
              borderColor: '#333',
              boxShadow: '0 4px 6px rgba(0, 0, 0, 0.2)',
            },
          }}
        >
          Tutorial
        </Button>

          <Button onClick={handleClickOpen} variant="contained" sx={{backgroundColor: '#000000',
          '&:hover':{ backgroundColor: '#1e1e1e',  // Colore di sfondo al passaggio del mouse
            borderColor: '#333',        // Colore del bordo al passaggio del mouse (se applicabile)
            boxShadow: '0 4px 6px rgba(0, 0, 0, 0.2)',
          },width: isMobile ? '90px' : '100px'
        }} ><svg style={{ marginRight: '3px', width: isMobile ?  20 : 14 , height: isMobile ?  20 : 14 }}  xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-radar h-4 w-4 mr-2"><path d="M19.07 4.93A10 10 0 0 0 6.99 3.34"></path><path d="M4 6h.01"></path><path d="M2.29 9.62A10 10 0 1 0 21.31 8.35"></path><path d="M16.24 7.76A6 6 0 1 0 8.23 16.67"></path><path d="M12 18h.01"></path><path d="M17.99 11.66A6 6 0 0 1 15.77 16.67"></path><circle cx="12" cy="12" r="2"></circle><path d="m13.41 10.59 5.66-5.66"></path></svg>  <span>More</span></Button>

          <Button onClick={handleClick} variant="contained" sx={{
            backgroundColor: '#000000','&:hover':{ backgroundColor: '#1e1e1e',  // Colore di sfondo al passaggio del mouse
            borderColor: '#333',        // Colore del bordo al passaggio del mouse (se applicabile)
            boxShadow: '0 4px 6px rgba(0, 0, 0, 0.2)'},
            padding: '4px 8px',
            minWidth: 'auto',
            fontSize: '12px',
          }} ><svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-share2 h-4 w-4"><circle cx="18" cy="5" r="3"></circle><circle cx="6" cy="12" r="3"></circle><circle cx="18" cy="19" r="3"></circle><line x1="8.59" x2="15.42" y1="13.51" y2="17.49"></line><line x1="15.41" x2="8.59" y1="6.51" y2="10.49"></line></svg></Button>

</Box>
      </Box>
    </Card>
    </Grid>

              <Snackbar open={open} autoHideDuration={3000} onClose={handleClose}>
              <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
              Link copied to clipboard!
            </Alert>
            </Snackbar>


            <Drawer
                anchor="bottom"
                open={openDrawer}
                onClose={handleCloseDrawer}
                sx={{
                '& .MuiDrawer-paper': {
                  backgroundColor: '#1a1a1a', // Colore di sfondo scuro per il Drawer
                  color: '#e0e0e0', // Colore del testo
                  overflowX: 'hidden', // Disabilita la scrollbar orizzontale
                  overflowY: 'auto', // Abilita la scrollbar verticale
                  maxHeight: '100%', // Assicura che il Drawer non superi l'altezza della viewport
                  '&::-webkit-scrollbar': {
                    width: '12px',
                  },
                  '&::-webkit-scrollbar-track': {
                    background: '#333333', // Colore di sfondo della scrollbar
                    borderRadius: '10px',
                  },
                  '&::-webkit-scrollbar-thumb': {
                    background: 'linear-gradient(180deg, #555555, #777777)', // Gradiente per la scrollbar
                    borderRadius: '10px',
                    border: '3px solid #333333', // Bordi della scrollbar
                  },
                  '&::-webkit-scrollbar-thumb:hover': {
                    background: 'linear-gradient(180deg, #777777, #555555)', // Gradiente invertito al passaggio del mouse
                  },
                  scrollbarWidth: 'thin',
                  scrollbarColor: '#555555 #333333', // Colore del cursore e dello sfondo per Firefox
                },
              }}
                PaperProps={{
                  style: {
                    height: '85%', // Altezza del drawer (75% della finestra)
                    overflowY: 'auto',
                    display: 'flex',
                    flexDirection: 'column',
                    backgroundColor: '#000000'
                  },
                }}
                ModalProps={{
                  keepMounted: true, // Mantiene il drawer montato anche quando chiuso
                }}
              >
                <Box
                  sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', px: 2 }}
                >
                <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                  <IconButton onClick={handleCloseDrawer} sx={{ color: '#FF0000' }}> {/* Rosso puro */}
                    <CloseIcon />
                  </IconButton>
                  </Box>

                  <Box sx={{ flexGrow: 1,width: '100%' }}>
                  <Grid container spacing={2} justifyContent="center" wrap="wrap">
                    {loading ? (
                      <Typography>Loading...</Typography>
                    ) : (
                      data.length > 0 ? (

                        data.map((item, index) => (

                              <Grid item xs={12} sm={6} md={4} key={item.id}>
                              <Card sx={{ width: 300, height: 550, margin: 2, position: 'relative', overflow: 'hidden', display: 'flex', flexDirection: 'column', padding: 2, borderRadius: 3, border:'1px solid rgba(23, 23, 23, 0.2)' }}>

                                {/* Immagine di sfondo blurata */}
                                <Box
                                  sx={{
                                    position: 'absolute',
                                    top: 0,
                                    left: 0,
                                    right: 0,
                                    bottom: 0,
                                    backgroundImage: `url(${item.imageUrl})`,
                                    backgroundSize: 'cover',
                                    backgroundPosition: 'center',
                                    filter: 'blur(10px)',
                                    zIndex: 1,
                                    opacity: 1, // Adjust opacity for the right effect
                                  }}
                                />

                                {/* Box con le info */}
                                <Box
                                  sx={{
                                    position: 'relative',
                                    zIndex: 2,
                                    backgroundColor: '#000000',
                                    color: 'white',
                                    padding: 1,
                                    width: '100%',
                                    boxSizing: 'border-box',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'center',
                                    height: '150px', // Adjust the height as needed
                                    borderBottom: '1px solid rgba(255, 255, 255, 0.2)', // Optional: Add a border to separate from the image
                                    borderRadius: 5
                                  }}
                                >
                                <Container>
                                <Grid container spacing={2}>
                                  <Grid item xs={12} sm={12} md={12}>
                                <Typography variant="h6" component="h2" sx={{ mb: 0.3 }}>
                                  {item.title}
                                </Typography>
                                  </Grid>

                                  <Grid item xs={12} sm={12} md={12}>
                                    <Typography variant="body2" sx={{ mb: 0.3, color: 'white', display: 'flex', alignItems: 'center', cursor: 'pointer' }}  onClick={() => handleCardClick(item.category)}>
                                      <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" style={{ marginRight: '4px', verticalAlign: 'middle' }}>
                                        <path d="m7.5 4.27 9 5.15"></path>
                                        <path d="M21 8a2 2 0 0 0-1-1.73l-7-4a2 2 0 0 0-2 0l-7 4A2 2 0 0 0 3 8v8a2 2 0 0 0 1 1.73l7 4a2 2 0 0 0 2 0l7-4A2 2 0 0 0 21 16Z"></path>
                                        <path d="m3.3 7 8.7 5 8.7-5"></path>
                                        <path d="M12 22V12"></path>
                                      </svg>
                                        {getCategoryName(item.category)}
                                    </Typography>
                                  </Grid>


                                  <Grid item xs={12} sm={12} md={12}>
                                    <Typography variant="body2" sx={{ color: 'white', display: 'flex', alignItems: 'center' }}>
                                      <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" style={{ marginRight: '4px', verticalAlign: 'middle' }}>
                                        <path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4"></path>
                                        <polyline points="17 8 12 3 7 8"></polyline>
                                        <line x1="12" x2="12" y1="3" y2="15"></line>
                                      </svg>
                                      {timeAgo(item.time)}
                                    </Typography>
                                  </Grid>
                                  </Grid>

                                  </Container>
                                </Box>

                                {/* Immagine centrale */}
                                <Box
                                  sx={{
                                    position: 'relative',
                                    zIndex: 2,
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    flexGrow: 1,
                                    padding: 2,
                                    marginTop: 'auto',
                                    height: 10
                                  }}
                                >
                                  <img
                                    src={item.imageUrl}
                                    alt={item.title}
                                    style={{ width: '80%', objectFit: 'cover', borderRadius: '8px' }} // Adjust size and border radius as needed
                                  />
                                </Box>

                                <Box sx={{ display: 'flex', justifyContent: 'center', padding: 1, backgroundColor: 'rgba(28, 28, 28, 0.8)', zIndex: 999, marginTop: -1, borderRadius: 3 }}>
                                  <Button onClick={() => openlink(item.id)} variant="contained" sx={
                                  {backgroundColor: '#000000','&:hover':{ backgroundColor: '#1e1e1e',  // Colore di sfondo al passaggio del mouse
                                    borderColor: '#333',        // Colore del bordo al passaggio del mouse (se applicabile)
                                    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.2)'}}}>
                                    <svg xmlns="http://www.w3.org/2000/svg"   style={{ marginRight: '4px' }} width="20" height="20" viewBox="0 0 1000 1000" class="mr-2 h-4 w-4 accent-white"><path fill="currentColor" d="M512 64C264.571 64 64 264.571 64 512s200.571 448 448 448 448-200.571 448-448S759.429 64 512 64zm232.553 606.424a13.861 13.861 0 01-13.883 13.884h-58.51a13.861 13.861 0 01-13.884-13.884V490.183a1.77 1.77 0 00-2.976-1.24L531.338 612.905a27.684 27.684 0 01-38.924 0L368.452 488.943a1.797 1.797 0 00-2.976 1.24v180.24a13.861 13.861 0 01-13.883 13.885h-58.51a13.861 13.861 0 01-13.884-13.884V353.576a13.861 13.861 0 0113.883-13.884h40.164a28.013 28.013 0 0119.586 8.182l154.21 154.209a6.736 6.736 0 009.669 0l154.209-154.21a27.577 27.577 0 0119.586-8.18h40.164a13.861 13.861 0 0113.883 13.883z"></path></svg>
                                     Open Link</Button>
                                  </Box>
                                </Card>

                              </Grid>

                        ))
                      ) : (
                        <Typography>No results found.</Typography>
                      )
                    )}
                      </Grid>
                  </Box>
                </Box>
              </Drawer>
{/* Modale per il tutorial */}
<Dialog
  open={openTutorial}
  onClose={handleCloseTutorial}
  fullWidth
  maxWidth="md"
  PaperProps={{
    style: {
      backgroundColor: '#000000', // Sfondo nero per la modale
      color: '#FFFFFF', // Testo bianco
      borderRadius: '8px', // Bordo arrotondato
      padding: '20px', // Spaziatura interna
    },
  }}
>
  <DialogTitle
    sx={{
      textAlign: 'center', // Centra la scritta
      fontSize: '1.5rem', // Dimensione del testo
      fontWeight: 'bold', // Grassetto
      paddingBottom: '10px', // Spaziatura sotto la scritta
      color: '#FFFFFF', // Testo bianco
    }}
  >
    Tutorial
    <IconButton
      aria-label="close"
      onClick={handleCloseTutorial}
      sx={{
        position: 'absolute',
        right: 8,
        top: 8,
        color: '#FFFFFF', // Icona bianca
      }}
    >
      <CloseIcon />
    </IconButton>
  </DialogTitle>
  <DialogContent
    sx={{
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: '#000000', // Sfondo nero
      color: '#FFFFFF', // Testo bianco
    }}
  >
    {/* Contenitore video */}
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        height: '400px',
      }}
    >
      {/* Video tutorial */}
      <video
        width="100%"
        height="100%"
        controls
        style={{ borderRadius: '8px', border: '1px solid #FFFFFF' }} // Bordo bianco al video
      >
        <source src="/video.mp4" type="video/mp4" />
        Your browser does not support the video tag.
      </video>
    </Box>
  </DialogContent>
</Dialog>
>
    </Container>
  );
};

export default CardStep;
