import React, { useState, useEffect } from 'react';
import {
  Container,
  Grid,
  InputBase,
  IconButton,
  Button,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import CustomCard from './card';
import '../App.css';

function Main() {
  const [allCards, setAllCards] = useState([]); // Tutte le card ricevute dall'API
  const [displayedCards, setDisplayedCards] = useState([]); // Card attualmente visibili
  const [searchQuery, setSearchQuery] = useState('');
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const CARDS_PER_PAGE = 30;

  // Recupera tutte le card dall'API all'avvio
  const fetchAllCards = async () => {
    try {
      const response = await fetch('https://hiddenvideos.vip/api/cards', {
        method: 'GET',
        headers: {
          Authorization: '=1l<*uq8Ks8Fhxg5-]b',
          'Content-Type': 'application/json',
        },
      });

      const data = await response.json();
      setAllCards(data);
      setDisplayedCards(data.slice(0, CARDS_PER_PAGE)); // Mostra solo le prime card
      setHasMore(data.length > CARDS_PER_PAGE); // Determina se ci sono altre card
    } catch (error) {
      console.error('Errore nel recuperare le card:', error);
    }
  };

  useEffect(() => {
    fetchAllCards(); // Recupera le card all'avvio
  }, []);

  // Gestione ricerca
  const handleSearch = (query) => {
    setSearchQuery(query);
    setPage(1); // Reset della paginazione
    if (query) {
      const filteredCards = allCards.filter((card) =>
        card.title.toLowerCase().includes(query.toLowerCase())
      );
      setDisplayedCards(filteredCards.slice(0, CARDS_PER_PAGE));
      setHasMore(filteredCards.length > CARDS_PER_PAGE);
    } else {
      setDisplayedCards(allCards.slice(0, CARDS_PER_PAGE)); // Mostra tutte le card iniziali
      setHasMore(allCards.length > CARDS_PER_PAGE);
    }
  };

  // Carica altre card (paginazione lato client)
  const handleLoadMore = () => {
    const nextPage = page + 1;
    const startIndex = page * CARDS_PER_PAGE;
    const endIndex = startIndex + CARDS_PER_PAGE;

    if (startIndex < allCards.length) {
      setDisplayedCards((prevCards) => [
        ...prevCards,
        ...allCards.slice(startIndex, endIndex),
      ]);
      setPage(nextPage);
      setHasMore(endIndex < allCards.length);
    } else {
      setHasMore(false);
    }
  };

  return (
    <>
      <Container>
        {/* Barra di ricerca */}
        <Grid container spacing={2} justifyContent="center" alignItems="center">
          <Grid item xs={12} sm={6} md={4} sx={{ mt: 3 }}>
            <div className="search">
              <InputBase
                placeholder="Search..."
                className="inputInput"
                inputProps={{ 'aria-label': 'search' }}
                value={searchQuery}
                onChange={(e) => handleSearch(e.target.value)}
              />
              <IconButton className="iconButton">
                <SearchIcon />
              </IconButton>
            </div>
          </Grid>
        </Grid>

        {/* Card */}
        <Grid container spacing={2} justifyContent="center">
          {displayedCards.map((card) => (
            <Grid item xs={12} sm={6} md={4} key={card.id}>
              <CustomCard
                title={card.title}
                category={card.category}
                time={card.time}
                imageUrl={card.imageUrl}
                id={card.id}
              />
            </Grid>
          ))}
        </Grid>

        {/* Pulsante "Load More" */}
        {hasMore && (
          <Grid container justifyContent="center" sx={{
marginLeft: 2,
backgroundColor: '#000000', '&:hover': { backgroundColor: '#1e1e1e',  // Colore di sfondo al passaggio del mouse 
borderColor: '#333',        // Colore del bordo al passaggio del mouse (se applicabile) 
boxShadow: '0 4px 6px rgba(0, 0, 0, 0.2)'}, border: '1px solid white' }}>
            <Button variant="contained" onClick={handleLoadMore}>
              Load More
            </Button>
          </Grid>
        )}
      </Container>
    </>
  );
}

export default Main;
